import {
  CSW_CLICK_OWN_BRAND_PRODUCT,
  OWN_BRANDS_CSW_HOME_AT_EXPERIMENT,
} from 'shared/experiments/consts'
import { activateExperiment } from 'shared/experiments/utils/experiments'
import { getExperimentPerTenantOnClient } from 'shared/experiments/utils/tenantBasedExperimentsUtils'
import trackOptimizelyEvent from 'shared/experiments/utils/trackOptimizelyEvent'

const eventsTracking = () => {
  const experiment = getExperimentPerTenantOnClient(
    OWN_BRANDS_CSW_HOME_AT_EXPERIMENT
  )

  const cswContainer = document.querySelector('.cro-3752')

  if (!experiment?.isEnabled) {
    return
  }

  void activateExperiment(experiment.name)
  cswContainer?.addEventListener('click', event => {
    const target = event.target as HTMLElement
    if (target.closest('.o-SliderHorizontal__item')) {
      trackOptimizelyEvent(CSW_CLICK_OWN_BRAND_PRODUCT)
    }
  })
}

export default eventsTracking
